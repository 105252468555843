<template>
  <v-col
    cols="auto"
    class="d-flex flex-column py-2"
    :style="message.from === me._id ? 'flex-direction: row-revers;' : ''"
    :class="[
      'max-width-50',
      !prevFromRepeat(index) ? 'pb-0' : '',
      message.from === me._id ? 'ms-auto' : '',
    ]"
  >
    <v-layout
      v-if="!prevFromRepeat(index)"
      align-center
      class="chat-user-avatar"
      :class="message.from === me._id ? 'ms-auto flex-row-reverse me' : 'you'"
    >
      <user-avatar :user="getMessageUser(message.from)" />
      <h5 class="primary--text text-no-wrap">
        {{ getMessageUserName(message.from) }}
      </h5>
    </v-layout>
    <v-tooltip
      :disabled="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      :left="message.from === me._id"
      :right="message.from !== me._id"
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- TODO: Integrate Calendar event message -->
        <div v-if="message.type === 'calendarEvent'">
          <v-sheet
            @click="toggleTimeOnMobile"
            v-bind="attrs"
            v-on="on"
            rounded
            :class="[
              message.from === me._id ? 'ta-chat-me' : 'ta-chat-you',
              prevFromRepeat(index) ? 'mt-0' : 'mt-5',
            ]"
            class="pb-4 pt-10 px-10 bg-transparent"
          >
            <v-layout align-end justify-center>
              <p class="body-2 mb-6">Request Sent</p>
            </v-layout>
            <v-layout align-end class="mb-3">
              <p class="body-2 mb-0 ms-auto secondary--text">Accepted</p>
              <v-progress-circular
                v-if="message.temp"
                class="ms-auto pl-3"
                size="22"
                indeterminate
                color="primary"
              >
              </v-progress-circular>
              <v-icon
                v-else
                class="ms-4 me-auto pl-3"
                size="18px"
                :class="
                  message.isRead
                    ? 'lightGrey--text'
                    : 'grey--text text-lighten-2'
                "
              >
                mdi-check-all
              </v-icon>
            </v-layout>
            <v-layout class="mt-15 mb-5">
              <v-btn color="primary" class="mx-auto"> Cancel Request </v-btn>
            </v-layout>
          </v-sheet>
        </div>

        <v-sheet
          v-else
          @click="toggleTimeOnMobile"
          v-bind="attrs"
          v-on="on"
          rounded
          :class="[
            message.from === me._id ? 'ta-chat-me' : 'ta-chat-you',
            prevFromRepeat(index) ? 'mt-0' : 'mt-5',
          ]"
          class="py-2 px-4"
        >
          <v-layout align-end>
            <p v-if="message.type === 'attachment'">
              <a
                v-if="!message.temp"
                class="text-decoration-none"
                target="_blank"
                :href="serverPublicUrl + message.attachment.url"
                :download="message.attachment.fileName"
              >
                <v-img
                  width="100"
                  v-if="message.attachment.fileType.includes('image')"
                  :src="serverPublicUrl + message.attachment.url"
                />
                <v-icon v-else>mdi-file-outline</v-icon>
                <small>{{ message.attachment.fileName }}</small>
              </a>
            </p>
            <p v-else class="font-size-13 mb-0 white-space-pre-wrap">
              {{ message.text }}
            </p>
            <v-progress-circular
              v-if="message.temp"
              class="ms-auto pl-3"
              size="22"
              indeterminate
              color="primary"
            >
            </v-progress-circular>
            <v-icon
              v-else
              class="ms-auto pl-3"
              size="18px"
              :class="
                message.isRead ? 'accent--text' : 'grey--text text-lighten-2'
              "
            >
              mdi-check-all
            </v-icon>
          </v-layout>
        </v-sheet>
      </template>
      <small>{{ message.createdAt | moment("MM-DD-YY h:mm:ss A") }}</small>
    </v-tooltip>
    <v-fade-transition v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <h6
        v-if="toggleTime"
        class="grey--text text-darken-2"
        v-click-outside="outside"
      >
        {{ message.createdAt | moment("MM-DD-YY h:mm:ss A") }}
      </h6>
    </v-fade-transition>
  </v-col>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";
export default {
  name: "Message",
  props: {
    messages: {
      required: true,
    },
    message: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  mixins: [UserMixin],

  data: () => {
    return {
      toggleTime: false,
    };
  },
  components: { UserAvatar },

  computed: {
    currentRoom() {
      return this.me.rooms.find((r) => r._id === this.$route.params.id);
    },

    serverPublicUrl() {
      return process.env.VUE_APP_SERVER_PUBLIC_URL + "/";
    },
  },

  methods: {
    getMessageUserName(id) {
      return this.getUserName(this.getMessageUser(id));
    },

    getMessageUser(id) {
      return id === this.me._id
        ? this.me
        : this.currentRoom.users.find((u) => u._id === id);
    },

    prevFromRepeat(i) {
      let current = this.messages[i];
      let prevElement = this.messages[i - 1];
      return prevElement?.from && current.from === prevElement.from;
    },

    nextFromRepeat(i) {
      let current = this.messages[i];
      let nextElement = this.messages[i - 1];
      return nextElement?.from && current.from === nextElement.from;
    },

    toggleTimeOnMobile() {
      this.toggleTime = !this.toggleTime;
    },

    outside() {
      this.toggleTime = false;
    },
  },
};
</script>
