<template>
  <v-col
    cols="12"
    md="9"
    class="pa-0 d-flex flex-column position-relative"
    :style="$vuetify.theme.themes.dark ? '' : 'background-color: #fafafa;'"
  >
    <v-toolbar
      elevation="0"
      class="position-sticky bg-white-gradient mh-64px z-index-1 w-100"
    >
      <v-btn
        class="d-md-none"
        icon
        plain
        color="primary"
        :to="{ name: 'Chat' }"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <!--      <v-chip v-if="!isTherapist" class="mb-0 font-size-12"-->
      <!--        >{{ $t("MessageCount") }}-->
      <!--        <strong class="ms-1 font-weight-medium"-->
      <!--          >{{ todayClientMessagesCount }}/20</strong-->
      <!--        ></v-chip-->
      <!--      >-->

      <v-btn
        v-if="dayLimitReached"
        icon
        class="mx-3"
        @click="notificationDialog = true"
      >
        <v-icon class="danger--text text--darken-4">mdi-alert-circle</v-icon>
      </v-btn>

      <v-btn
        v-if="showNotificationAlertDialog"
        icon
        class="mx-3"
        @click="notificationDialog = true"
      >
        <v-icon class="warning--text">mdi-alert-outline</v-icon>
      </v-btn>

      <!-- <v-chip class="danger--text flex-wrap text--darken-2 font-weight-medium mb-0 ms-5 font-size-12" v-if="dayLimitReached">

        You have reached your daily chant limit. You can chat again tomorrow
      </v-chip> -->
      <div v-if="isTherapist">
        <v-chip
          class="mb-0 font-size-13 ms-2 px-5"
          height="24"
          @click="goToClientsProfile"
        >
          <v-icon left small>mdi-account-details-outline</v-icon>
          <span class="d-none d-sm-block">
            {{ getUserName(roomClient) }}
            {{ $t("Profile") }}
          </span>
        </v-chip>
      </div>
    </v-toolbar>

    <v-container
      id="chat-section"
      v-chat-scroll="{ always: false }"
      @v-chat-scroll-top-reached="getOldMessages"
    >
      <div>
        <v-row v-if="loading" class="pb-2 pt-15">
          <v-progress-circular
            class="mx-auto flex-grow-1 flex-shrink-1"
            size="22"
            indeterminate
            color="primary"
          >
          </v-progress-circular>
        </v-row>

        <RoomMessages :messages="messages" />
        <RoomMessages :messages="tmpMessages" />
      </div>
    </v-container>

    <v-footer
      :app="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      inset
      class="pa-0 mt-auto bg-transparent"
    >
      <v-sheet class="py-5 px-md-10 px-5 bg-transparent w-100" elevation="1">
        <form @submit.prevent="onFormSubmit">
          <v-sheet
            color="muted lighten-1"
            class="therapist-ribbon text-center"
            v-if="!isTherapist"
          >
            <span class="primary--text body-2"
              >{{
                $t(
                  "*Due to the nature of chat messaging, your conversation might not be in real time. Your therapist should be able to respond to you"
                )
              }}
              <b>{{ $t("within 24 hours") }}</b></span
            >
          </v-sheet>
          <v-textarea
            outlined
            rows="1"
            hide-details
            auto-grow
            :append-outer-icon="$vuetify.rtl ? 'mdi-arrow-right' : 'mdi-send'"
            :class="!canSendMessage ? 'cursor-disabled' : ''"
            slot="activator"
            clear-icon="mdi-close-circle"
            clearable
            type="text"
            solo
            flat
            v-model="typedText"
            :label="$t('Message')"
            :disabled="!canSendMessage"
            @click:append-outer="onFormSubmit"
            @click:clear="clearMessage"
            @keydown.enter.exact.prevent="onFormSubmit"
          >
            <template v-slot:append class="mt-0">
              <v-icon class="mx-2" @click="openFileInput">
                mdi-attachment
              </v-icon>

              <!-- <v-icon class="mx-2">
                mdi-microphone
              </v-icon> -->

              <v-icon class="mx-2" @click="toggleDialogEmoji">
                mdi-emoticon
              </v-icon>

              <VEmojiPicker
                v-show="emojiDialog"
                :style="{ width: '300', height: '200' }"
                labelSearch="Search"
                @select="selectEmoji"
              />
            </template>
          </v-textarea>

          <input
            ref="fileInputRef"
            type="file"
            name="attachment"
            :disabled="loading"
            class="d-none"
            @change="filesChange"
          />

          <!-- <v-text-field v-model="typedText" outlined color="primary" type="text" label="Message" clearable
            append-outer-icon="mdi-send" clear-icon="mdi-close-circle"
            :dense="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false" hide-details
            :disabled="dayLimitReached" @click:append-outer="onFormSubmit" @click:clear="clearMessage">
            <template v-slot:prepend class="mt-0">
              <v-icon style="transform: rotate(90deg)"
                :size="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '24' : '36'">
                mdi-attachment
              </v-icon>
            </template>
          </v-text-field> -->
        </form>
      </v-sheet>
    </v-footer>

    <v-dialog v-model="notificationDialog" width="700">
      <v-card class="rounded-lg">
        <v-btn
          @click="notificationDialog = false"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="d-flex justify-content-center pt-5">
          <!-- If chat limit reached -->
          <v-icon
            v-if="dayLimitReached"
            x-large
            class="danger--text text--darken-4"
            >mdi-alert-circle</v-icon
          >

          <!-- If any alert -->
          <v-icon
            v-else-if="showNotificationAlertDialog"
            x-large
            class="warning--text"
            >mdi-alert-outline</v-icon
          >
        </v-card-text>

        <v-card-text class="pa-8">
          <!--  -->

          <p v-if="dayLimitReached" class="text-center px-md-8">
            {{ $t("DailyLimitReached") }}
          </p>

          <!--          <p-->
          <!--            v-if="!clientHaveSubscriptionForChat && !isTherapist"-->
          <!--            class="text-center px-md-8"-->
          <!--          >-->
          <!--            {{-->
          <!--              $t(-->
          <!--                "Your Current plan does not include chat feature. You can chat with your therapist in case you want to discuss session timings. Choose one of our subscription plans to chat with your therapist."-->
          <!--              )-->
          <!--            }}-->
          <!--          </p>-->

          <p
            v-if="!clientHaveSubscriptionForChat && isTherapist"
            class="text-center px-md-8"
          >
            {{
              $t(
                "Your client did not subscribe to the chat feature. You can still chat with your client in case you want to discuss session timings."
              )
            }}
          </p>

          <p
            v-else-if="clientDayLimitReached && isTherapist"
            class="text-center px-md-8"
          >
            {{
              $t(
                "Your client have reached their daily chat limit and they are notified. You can still chat with your client if needed."
              )
            }}
          </p>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="notificationDialog = false">
            {{ $t("Ok") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import UserMixin from "@/mixins/UserMixin";
import RoomMessages from "@/components/Chat/RoomMessages";
import { v4 } from "uuid";

const moment = require("moment");

export default {
  name: "Room",
  components: { RoomMessages, VEmojiPicker },

  mixins: [UserMixin],
  data: () => {
    return {
      notificationDialog: false,
      messages: [],
      tmpMessages: [],
      roomId: null,
      emojiDialog: false,
      typedText: "",
      loading: true,

      uploadedFile: null,
      fileBuffer: null,
    };
  },

  computed: {
    currentRoom() {
      return this.me.rooms.find((r) => r._id === this.$route.params.id);
    },

    roomClient() {
      if (!this.isTherapist) {
        return this.me;
      }

      return this.getClientById(this?.currentRoom?.users[0].id);
    },

    clientHaveSubscriptionForChat() {
      let stripeSubscription = this.subscription?.stripeSubscription;
      if (this.isTherapist) {
        stripeSubscription = this.roomClient?.subscription?.stripeSubscription;
      }

      return ["Basic", "Starter", "Premium"].includes(
        stripeSubscription?.plan?.metadata?.type
      );
    },

    dayLimitReached() {
      if (this.isTherapist) {
        return false;
      }

      return this.todayClientMessagesCount >= 20;
    },

    showNotificationAlertDialog() {
      return (
        (!this.clientHaveSubscriptionForChat && this.isTherapist) ||
        (this.clientDayLimitReached && this.isTherapist)
      );
    },

    clientDayLimitReached() {
      return this.todayClientMessagesCount >= 20;
    },

    todayClientMessagesCount() {
      return this.messages.filter(
        (m) =>
          moment(m.createdAt).isSame(moment(), "day") &&
          m.from === this.roomClient._id
      ).length;
    },

    roomOtherUsers() {
      return this?.currentRoom?.users || [];
    },

    canSendMessage() {
      if (this.isTherapist) return true;
      return this.myTherapistId === this.roomOtherUsers[0]._id;
    },
  },

  methods: {
    selectEmoji(emoji) {
      this.typedText += emoji.data;
      this.toggleDialogEmoji();
    },

    openFileInput() {
      if (this.canSendMessage) {
        this.$refs.fileInputRef.click();
      }
    },

    async filesChange() {
      this.uploadedFile = this.$refs.fileInputRef.files[0];
      if (this.uploadedFile) {
        const filesize = (this.uploadedFile.size / 1024 / 1024).toFixed(4);
        if (filesize > 5) {
          alert("max file size limit 5MB");
        } else {
          this.fileBuffer = await this.toBuffer(this.uploadedFile);
          this.startUpload();
        }
        // this.loading = true;
      }

      // this.clearFileInput()
      // this.fileBase64String = await this.toBase64(this.uploadedFile);
    },

    uploadChunk(id, index, chunks, cb) {
      this.$socket.emit(
        "upload-chunk",
        {
          id: id,
          data: chunks[index],
        },
        () => {
          if (index < chunks.length - 1) {
            this.uploadChunk(id, index + 1, chunks, cb);
          } else {
            this.$socket.emit(
              "attachment-upload-end",
              {
                id: id,
                roomId: this.roomId,
                fileName: this.uploadedFile.name,
                fileType: this.uploadedFile.type,
              },
              (data) => {
                if (typeof cb === "function") {
                  cb(data);
                }
              }
            );
          }
        }
      );
    },

    startUpload() {
      const chunkSize = 500000; // 500kb
      const buffLen = Math.ceil(this.fileBuffer.byteLength / chunkSize);
      let splitChunks = [];
      for (let i = 0; i < buffLen; i++) {
        splitChunks.push(
          this.fileBuffer.slice(i * chunkSize, chunkSize * (i + 1))
        );
      }

      // TODO: error handling
      this.$socket.emit(
        "request-file-upload",
        {
          name: this.uploadedFile.name,
          type: this.uploadedFile.type,
        },
        (data) => {
          let id = data.id;
          if (id) {
            let message = {
              _id: v4(),
              text: "",
              type: "attachment",
              attachment: {
                url: "",
                type: this.uploadedFile.type,
                name: this.uploadedFile.name,
              },
              from: this.me._id,
              temp: true,
            };

            this.tmpMessages.push(message);

            this.uploadChunk(id, 0, splitChunks, (res) => {
              if (res.path) {
                console.log("res.path: ", res.path);
                // this.me.profile.avatar = res.path;
              }
              this.loading = false;
            });
          }
        }
      );
    },

    toBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    toggleDialogEmoji() {
      if (this.canSendMessage) {
        this.emojiDialog = !this.emojiDialog;
      }
    },

    addSocketEvents() {
      this.$socket.on("new-message", (data) => {
        this.messages.push(data);

        this.$socket.emit("read-messages", {
          room: this.$route.params.id,
        });

        if (data.from === this.me._id) {
          let index = null;
          if (data.type === "text") {
            index = this.tmpMessages.findIndex((e) => e.text === data.text);
          } else {
            index = this.tmpMessages.findIndex(
              (e) => e.fileName === data.fileName
            );
          }

          this.tmpMessages.splice(index, 1);
        }
      });

      this.$socket.on("read-messages-client", (data) => {
        this.messages.forEach((mess) => {
          if (mess.from !== data.user && !mess.isRead) {
            mess.isRead = true;
          }
        });
      });
    },

    goToClientsProfile() {
      // this.me.rooms.some(e => {
      //   if (e._id === item._id) {
      //     this.$router.push({ name: "PatientProfile", params: { id: e._id } })
      //   }
      // })
      this.me.rooms.some((e) => {
        // console.log('e id',e._id)
        if (e._id === this.$route.params.id) {
          this.$router.push({
            name: "ClientFile",
            params: { userId: e.users[0]._id },
          });
        }
      });
    },

    removeListeners() {
      this.$socket.off("new-message");
      this.$socket.off("read-messages-client");
    },

    getOldMessages() {
      this.getMessages({
        limit: 10,
        skip: this.messages.length,
        cb: (data) => {
          this.messages.unshift(...data);
        },
      });
    },

    onFormSubmit() {
      // (this.typedText.trim() && !this.dayLimitReached)
      if (this.typedText.trim() && this.canSendMessage) {
        let message = {
          _id: v4(),
          text: this.typedText.trim(),
          type: "text",
          from: this.me._id,
          temp: true,
        };
        this.typedText = "";
        this.tmpMessages.push(message);

        this.sendMessage(message);
      }
    },

    joinRoom() {
      this.$socket.emit(
        "join-room",
        {
          room: this.roomId,
        },
        (e) => {
          console.log("join-room", e);
        }
      );
    },

    leaveRoom() {
      // console.log("leaveRoom: ");
      // console.log("this.$route.params.id: ", this.$route.params.id);
      this.$socket.emit(
        "leave-room",
        {
          room: this.roomId,
        },
        (e) => {
          console.log("leave-room", e);
        }
      );
    },

    sendMessage(message, cb) {
      this.$socket.emit(
        "send-message",
        {
          room: this.roomId,
          message: message,
        },
        (data) => {
          if (typeof cb === "function") {
            cb(data);
          }
        }
      );
    },

    getMessages({ limit = 20, skip = 0, cb = null } = {}) {
      this.loading = true;
      this.$socket.emit(
        "get-messages",
        {
          room: this.$route.params.id,
          limit: limit,
          skip: skip,
        },
        (data) => {
          if (typeof cb === "function") {
            cb(data.reverse());
          }
          this.loading = false;
        }
      );
    },

    clearMessage() {
      this.message = "";
    },
  },

  mounted() {
    this.roomId = this.$route.params.id;

    this.awaitPromiseCallback({
      key: "getMe",
      cb: () => {
        if (!this.currentRoom) {
          return this.$router.replace({ name: "Chat" });
        }

        this.$socketService.addConnectionCallback({
          id: "joinRoom",
          cb: this.joinRoom.bind(this),
        });

        this.addSocketEvents();
        this.joinRoom();
        this.getMessages({
          limit: 20,
          skip: 0,
          cb: (data) => {
            this.messages = data;
          },
        });

        this.$socket.emit("read-messages", {
          room: this.$route.params.id,
        });
        // this.$socket.emit('join-room', {
        //   room: "61daf568ed971a7c2a7fd1e6"
        // }, (e) => {
        //   console.log(e)
        // })
      },
    });
  },

  beforeDestroy() {
    this.$socketService.removeConnectionCallback("joinRoom");
    this.removeListeners();
    this.leaveRoom();
  },
};
</script>

<style scoped>
.v-textarea::v-deep .v-input__append-inner,
.v-textarea::v-deep .v-input__append-outer {
  align-self: end !important;
  margin-top: auto !important;
  /* margin-bottom: auto !important; */
  padding-bottom: 16px;
}

.v-textarea::v-deep .v-input__append-outer .v-input__icon {
  margin-top: 16px;
}

/* .v-textarea::v-deep textarea {
    height: 0 !important;
  } */

#EmojiPicker {
  width: 300px;

  position: absolute;
  bottom: 40px;
  right: 0px;
  z-index: 10;
}

/* height: 160px !important; */
</style>
