var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"d-flex flex-column py-2",class:[
    'max-width-50',
    !_vm.prevFromRepeat(_vm.index) ? 'pb-0' : '',
    _vm.message.from === _vm.me._id ? 'ms-auto' : '' ],style:(_vm.message.from === _vm.me._id ? 'flex-direction: row-revers;' : ''),attrs:{"cols":"auto"}},[(!_vm.prevFromRepeat(_vm.index))?_c('v-layout',{staticClass:"chat-user-avatar",class:_vm.message.from === _vm.me._id ? 'ms-auto flex-row-reverse me' : 'you',attrs:{"align-center":""}},[_c('user-avatar',{attrs:{"user":_vm.getMessageUser(_vm.message.from)}}),_c('h5',{staticClass:"primary--text text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getMessageUserName(_vm.message.from))+" ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,"left":_vm.message.from === _vm.me._id,"right":_vm.message.from !== _vm.me._id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(_vm.message.type === 'calendarEvent')?_c('div',[_c('v-sheet',_vm._g(_vm._b({staticClass:"pb-4 pt-10 px-10 bg-transparent",class:[
            _vm.message.from === _vm.me._id ? 'ta-chat-me' : 'ta-chat-you',
            _vm.prevFromRepeat(_vm.index) ? 'mt-0' : 'mt-5' ],attrs:{"rounded":""},on:{"click":_vm.toggleTimeOnMobile}},'v-sheet',attrs,false),on),[_c('v-layout',{attrs:{"align-end":"","justify-center":""}},[_c('p',{staticClass:"body-2 mb-6"},[_vm._v("Request Sent")])]),_c('v-layout',{staticClass:"mb-3",attrs:{"align-end":""}},[_c('p',{staticClass:"body-2 mb-0 ms-auto secondary--text"},[_vm._v("Accepted")]),(_vm.message.temp)?_c('v-progress-circular',{staticClass:"ms-auto pl-3",attrs:{"size":"22","indeterminate":"","color":"primary"}}):_c('v-icon',{staticClass:"ms-4 me-auto pl-3",class:_vm.message.isRead
                  ? 'lightGrey--text'
                  : 'grey--text text-lighten-2',attrs:{"size":"18px"}},[_vm._v(" mdi-check-all ")])],1),_c('v-layout',{staticClass:"mt-15 mb-5"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"primary"}},[_vm._v(" Cancel Request ")])],1)],1)],1):_c('v-sheet',_vm._g(_vm._b({staticClass:"py-2 px-4",class:[
          _vm.message.from === _vm.me._id ? 'ta-chat-me' : 'ta-chat-you',
          _vm.prevFromRepeat(_vm.index) ? 'mt-0' : 'mt-5' ],attrs:{"rounded":""},on:{"click":_vm.toggleTimeOnMobile}},'v-sheet',attrs,false),on),[_c('v-layout',{attrs:{"align-end":""}},[(_vm.message.type === 'attachment')?_c('p',[(!_vm.message.temp)?_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":_vm.serverPublicUrl + _vm.message.attachment.url,"download":_vm.message.attachment.fileName}},[(_vm.message.attachment.fileType.includes('image'))?_c('v-img',{attrs:{"width":"100","src":_vm.serverPublicUrl + _vm.message.attachment.url}}):_c('v-icon',[_vm._v("mdi-file-outline")]),_c('small',[_vm._v(_vm._s(_vm.message.attachment.fileName))])],1):_vm._e()]):_c('p',{staticClass:"font-size-13 mb-0 white-space-pre-wrap"},[_vm._v(" "+_vm._s(_vm.message.text)+" ")]),(_vm.message.temp)?_c('v-progress-circular',{staticClass:"ms-auto pl-3",attrs:{"size":"22","indeterminate":"","color":"primary"}}):_c('v-icon',{staticClass:"ms-auto pl-3",class:_vm.message.isRead ? 'accent--text' : 'grey--text text-lighten-2',attrs:{"size":"18px"}},[_vm._v(" mdi-check-all ")])],1)],1)]}}])},[_c('small',[_vm._v(_vm._s(_vm._f("moment")(_vm.message.createdAt,"MM-DD-YY h:mm:ss A")))])]),(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)?_c('v-fade-transition',[(_vm.toggleTime)?_c('h6',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.outside),expression:"outside"}],staticClass:"grey--text text-darken-2"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.message.createdAt,"MM-DD-YY h:mm:ss A"))+" ")]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }