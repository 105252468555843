<template>
  <div>
    <v-row
      :class="[prevFromRepeat(i) ? 'mt-0 py-0' : 'mt-5 py-2']"
      v-for="(message, i) in messages"
      :ref="message._id"
      :key="message._id"
    >
      <Message :messages="messages" :message="message" :index="i" />
    </v-row>
  </div>
</template>

<script>
import Message from "@/components/Chat/Message";
export default {
  name: "RoomMessages",
  components: {
    Message,
  },
  props: {
    messages: {
      required: true,
    },
  },

  methods: {
    prevFromRepeat(i) {
      let current = this.messages[i];
      let prevElement = this.messages[i - 1];
      return prevElement?.from && current.from === prevElement.from;
    },

    nextFromRepeat(i) {
      let current = this.messages[i];
      let nextElement = this.messages[i - 1];
      return nextElement?.from && current.from === nextElement.from;
    },
  },
};
</script>
