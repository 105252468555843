<template>
  <v-container class="fill-height py-3">
    <!-- <BrowserAlert /> -->
    <v-card
      class="mx-auto fill-height w-100 bg-transparent overflow-hidden"
      elevation="0"
      outlined
      rounded="lg"
    >
      <v-row class="ma-0 fill-height">
        <v-col
          cols="12"
          md="3"
          class="pa-0 border-right"
          :class="[
            { 'd-none d-md-flex flex-md-column': isInRoom },
            { 'd-flex flex-column justify-center align-center': !rooms.length },
          ]"
        >
          <v-list two-line class="py-0 chat-list" evaluation="0">
            <v-list-item-group>
              <v-list-item
                v-for="room in rooms"
                plain
                :key="room._id"
                :to="{ name: 'Room', params: { id: room._id } }"
                class="border-bottom"
              >
                <v-list-item-avatar class="me-4">
                  <user-avatar :user="room.users[0]" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getUserName(room.users[0]) }}
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle class="font-size-12">My last message goes here</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <p v-if="!rooms.length" class="primary--text">
            {{ $t("You have no rooms to select") }}
          </p>
        </v-col>
        <v-col
          cols="12"
          v-if="$route.name === 'Chat'"
          md="9"
          class="d-flex flex-column position-mobile-absolute fill-height justify-center align-center"
        >
          <p class="primary--text">
            Select a chat room to start a conversation
          </p>
        </v-col>

        <router-view :key="$route.path" />
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
// import BrowserAlert from "@/components/BrowserAlert";
import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "Chat",
  components: {
    UserAvatar,
    // BrowserAlert
  },
  mixins: [UserMixin],

  data: () => {
    return {
      toggleChat: true,
    };
  },

  computed: {
    rooms() {
      return this?.me?.rooms || [];
    },

    isInRoom() {
      return this.$route.name === "Room";
    },
  },

  methods: {},

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: () => {
        // this.addSocketEvents()
        // this.$socket.emit('join-room', {
        //   room: "61daf568ed971a7c2a7fd1e6"
        // }, (e) => {
        //   console.log(e)
        // })
      },
    });
  },
};
</script>

<style lang="scss">
.chat-list {
  max-height: calc((var(--vh, 1vh) * 100) - 111px);
  overflow-y: auto;
}
</style>
